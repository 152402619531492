import React from "react";
import { Container, Image, DropdownButton, Dropdown } from "react-bootstrap";
import logo from "../TL_Logo.png";

function Header(props) {
    if (props.loggedIn) {
        let user = props.user;
        if (user && props.user.length > 8) {
            user = `${user.substring(0,5)}...${user.substring(user.length-3)}`
        }
        else {
            user = "";
        }
        return (
            <Container className="d-flex align-items-center"fluid>
                <Image fluid rounded src={logo} style={{height: "10.5vh"}}/>
                <h2 className="mb-0">Transient Labs Ethereum Portal</h2>
                <DropdownButton variant="outline-primary" size="sm" className="ms-auto float-end" title={user}>
                    <Dropdown.Item as="button" onClick={props.onLogout}>Logout</Dropdown.Item>
                </DropdownButton>
            </Container>
        );
    }
    else {
        return (
            <Container className="d-flex align-items-center"fluid>
                <Image fluid rounded src={logo} style={{height: "10.5vh"}}/>
                <h2 className="mb-0">Transient Labs Ethereum Portal</h2>
            </Container>
        );
    }   
}

export default Header