import React from 'react';
import { createRoot } from 'react-dom/client';
import './customStyle.scss';
import { Alert, Modal } from 'react-bootstrap';
import { ethers } from 'ethers';
import Web3Modal from 'web3modal';
import CoinbaseWalletSDK from "@coinbase/wallet-sdk";
import WalletConnect from "@walletconnect/web3-provider";
import { Header, Enter, Contracts } from './components';
import "./fonts/BarlowCondensed-Light.ttf";
import "./fonts/BarlowCondensed-Regular.ttf";
import "./fonts/BarlowCondensed-Medium.ttf";

const providerOptions = {
    walletlink: {
        package: CoinbaseWalletSDK,
        options: {
            appName: "Transient Labs Ethereum Portal",
            infuraId: "5023ed38213e49f79cb55bbb7e4c58ba"
        }
    },
    walletconnect: {
        package: WalletConnect,
        options: {
            infuraId: "5023ed38213e49f79cb55bbb7e4c58ba"
        }
    }
};

const web3Modal = new Web3Modal({
    network: "mainnet",
    providerOptions
});

const networkId = 1;

const apiEndpoint = "https://us-central1-the-lab-3da06.cloudfunctions.net/ethlab";

class App extends React.Component {
    constructor(props) {
        super(props);
        // set initial state of App
        this.state = {
            loggingIn: false,
            loggedIn: false,
            user: null,
            alert: false,
            alertText: "",
            instance: null,
            wc: null,
            provider: null,
            signer: null,
            page: 0,
            pages: 1,
            contracts: [],
            modalState: 0,
            deploying: false,
            contractAddr: null,
            txPending: false,
            txConfirmed: false,
            verificationSubmitted: false,
            verificationPending: false
        }
    }

    async login() {
        // make sure the user isn't logged in somehow
        if (this.state.loggedIn) {
            return;
        }
        // variables
        let instance;
        let wc;
        let provider;
        let signer;
        let addr;
        let sig;
        let pages;
        // get user to connect
        try {
            instance = await web3Modal.connect();
            wc = instance.wc;
        }
        catch (error) {
            console.log(error.message);
            return;
        }
        // setup ethersjs
        provider = new ethers.providers.Web3Provider(instance);
        signer = provider.getSigner();
        addr = await signer.getAddress();
        
        // setup callbacks
        instance.on("accountsChanged", (accounts) => {
            if (accounts[0].toLowerCase() !== this.state.user) {
                this.logout();
            }
        });
        instance.on("disconnect", (accounts) => {
            this.logout();
        });
        instance.on("chainChanged", async (chainId) => {
            let chain = parseInt(chainId, 16);
            if (chain === networkId) {
                this.setState({ modalState: 0 });
            }
            else {
                this.setState({ modalState: 2 });
            }
        });

        // ask for user signature
        try {
            sig = await signer.signMessage("Log into the Transient Labs Ethereum Portal");
        }
        catch (error) {
            console.log(error.message);
            return;
        }
        // alert user that log in is ongoing
        this.setState({ loggingIn: true });
        // send sig to api for login verification and cookie session start
        let body = {
            user: addr,
            sig: sig
        };
        let response = await fetch(`${apiEndpoint}/login`, {
            method: "PUT",
            headers: {
                'Content-Type': "application/json"
            },
            body: JSON.stringify(body),
            credentials: "include"
        });
        if (response.status === 200) {
            // user logged in successfully
            this.setState({ loggedIn: true, loggingIn: false })

            // get number of pages
            let resp = await fetch(`${apiEndpoint}/pages?user=${addr}`, {
                credentials: "include"
            });
            if (resp.status === 200) {
                let data = await resp.json();
                pages = data.pages;
                if (pages === 0) {
                    this.setState({ alert: true, alertText: "No Contracts Found" });
                    return;
                }
                // get first page
                let res = await fetch(`${apiEndpoint}/contracts/${0}?user=${addr}`, {
                    credentials: "include"
                });
                if (res.status === 200) {
                    let d = await res.json();
                    let c = d.contracts;
                    // update state
                    this.setState({
                        loggingIn: false,
                        loggedIn: true,
                        user: addr,
                        alert: false,
                        alertText: "",
                        instance: instance,
                        wc: wc,
                        provider: provider,
                        signer: signer,
                        page: 0,
                        pages: pages,
                        contracts: c,
                        modalState: 0
                    });
                    return;
                }
                else {
                    this.setState({ alert: true, alertText: "No Contracts Found" });
                    return;
                }
            }
            else {
                this.setState({ alert: true, alertText: "No Contracts Found" });
                return;
            }
        }
        else {
            this.setState({ alert: true, alertText: "Login Unsuccessful" });
            return;
        }
    }

    async logout() {
        // send logout request to the api
        await fetch(`${apiEndpoint}/logout`, {
            method: "PUT"
        });
        // clear cached provider
        web3Modal.clearCachedProvider();
        // logout wallet connect
        if (this.state.wc) {
            this.state.wc.killSession();
        }
        // try disconnecting instance
        try {
            this.state.instance.close();
        }
        catch (error) {
        }
        // update state to logged out and remove contract data
        this.setState({
            loggingIn: false,
            loggedIn: false,
            user: null,
            alert: false,
            alertText: "",
            instance: null,
            wc: null,
            provider: null,
            signer: null,
            page: 0,
            pages: 1,
            contracts: [],
            modalState: 0,
            deploying: false,
            contractAddr: null,
            txPending: false,
            txConfirmed: false,
            gasUsed: null,
            verificationPending: false
        });
    }

    async changePages(i) {
        // get page data from api
        let response = await fetch(`${apiEndpoint}/contracts/${i}?user=${this.state.user}`, {
            credentials: "include"
        });
        if (response.status === 200) {
            let data = await response.json();
            let c = data.contracts;

            // update state
            this.setState({
                contracts: c,
                page: i
            });
            return;
        }
        else {
            return;
        }
    }

    async deploy(i) {
        if (!this.state.deploying){
            let net = await this.state.provider.getNetwork();
            if (net.chainId !== networkId) {
                this.setState({modalState: 2});
                return;
            }
            // get contract info
            let contract = this.state.contracts[i];

            // get deployment data for the proper contract entry
            let response = await fetch(`${apiEndpoint}/contract-data?user=${this.state.user}&name=${contract.name}`, {
                credentials: "include"
            });
            if (response.status === 200) {
                this.setState({
                    deploying: true
                });
                try {
                    let data = await response.json();
                    let abi = data.abi;
                    let bytecode = data.bytecode;
                    let intrface = new ethers.utils.Interface(abi);
                    let contractFactory = new ethers.ContractFactory(intrface, bytecode, this.state.signer);
                    // create a transaction to deploy and wait for the user to transact
                    try {
                        let tx = await contractFactory.deploy(...data.args);
                        this.setState({modalState: 1, contractAddr: tx.address, txPending: true});
                        let receipt;
                        try {
                            receipt = await tx.deployTransaction.wait();
                            
                        }
                        catch (error) {
                            this.setState({modalState: 5, txPending: false});
                            return;
                        }
                        // tx confirmed
                        this.setState({modalState: 3, txPending: false, txConfirmed: true, gasUsed: receipt.gasUsed.toNumber() * 0.000000001 });
                        // submit for verification
                        try {
                            let resp = await fetch(`${apiEndpoint}/verify?user=${this.state.user}&addr=${this.state.contractAddr}&name=${contract.name}`, {
                                credentials: "include"
                            });
                            if (resp.status === 200) {
                                // verification submitted properly
                                this.setState({modalState: 4, verificationPending: true});
                                return;
                            }
                            else {
                                // something went wrong
                                this.setState({modalState: 6})
                            }
                        }
                        catch (error) {
                            this.setState({modalState: 6})
                        }
                    }
                    catch (error){
                        console.log(error.message);
                        this.finishDeploy();
                        return;
                    }
                }
                catch(error) {
                    console.log(error.message);
                    this.setState({ alert: true, alertText: `Something went wrong deploying ${contract.name}` });
                    return;
                }
            }
            else {
                this.setState({ alert: true, alertText: `Something went wrong getting data for ${contract.name}` });
                return;
            }
        }
        else {
            alert("Already deploying a contract... please wait")
        }
    }
    
    finishDeploy() {
        this.setState({
            deploying: false,
            contractAddr: null,
            txPending: false,
            txConfirmed: false,
            gasUsed: null,
            verificationPending: false,
            modalState: 0
        });
        this.changePages(this.state.page);
    }

    renderBody() {
        if (this.state.alert) {
            return (
                <Alert variant="danger" onClose={() => {this.logout()}} dismissible>
                    <Alert.Heading>{this.state.alertText}</Alert.Heading>
                    <p>
                        If this error continues to appear, please reach out to Transient Labs. We apologize in advance!
                    </p>
                </Alert>
            );
        }
        else {
            if (this.state.loggedIn) {
                if (this.state.contracts.length !== 0) {
                    return (<div className="justify-content-center d-flex align-items-top" style={{ height: "75vh", width: "85vw" }}>
                                <Contracts contracts={this.state.contracts} paginationCallback={(i) => { this.changePages(i)}} deployCallback={(i) => { this.deploy(i) }} pages={this.state.pages} page={this.state.page}/>
                            </div>);
                }
                else {
                    return (
                        <Alert variant="success">
                            <Alert.Heading>Login Successful!</Alert.Heading>
                            <p>
                                Fetching contracts... 📜
                            </p>
                        </Alert>
                    );
                }
            }
            else {
                if (this.state.loggingIn) {
                    <Alert variant="success">
                        <Alert.Heading>Login in Process</Alert.Heading>
                        <p>
                            Accessing the mainframe... 💻
                        </p>
                    </Alert>
                }
                else {
                    return (<div className="justify-content-center d-flex align-items-center" style={{ height: "75vh" }}>
                                <Enter onClick={() => this.login()} />
                            </div>);
                }
            }
        }
    }

    renderModal() {
        // case 0 - nothing
        // case 1 - deploy transaction submitted
        // case 2 - chain change... need to alert users to change back to mainnet
        // case 3 - deploy transaction confirmed
        // case 4 - contract verification pending
        // case 5 - deploy transaction failed
        // case 6 - contract verification submission failed
        switch (this.state.modalState) {
            case 0:
                return;
            case 1:
                return (<Modal show={true} backdrop="static" keyboard={false}>
                            <Modal.Header>
                                <Modal.Title>Transaction Submitted!</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p>✅ Transaction Submitted</p>
                                <p className="mb-0">&emsp;Contract Address:</p> <p>&emsp;{this.state.contractAddr}</p>
                                <p>⏳ Waiting on Transaction Confirmation...</p>
                            </Modal.Body>
                        </Modal>);
            case 2:
                return (<Modal show={true} backdrop="static" keyboard={false}>
                            <Modal.Header>
                                <Modal.Title>Network Change Detected</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                Please change back to Ethereum Mainnet to continue using this portal!
                            </Modal.Body>
                        </Modal>);
            case 3:
                return (<Modal show={true} backdrop="static" keyboard={false}>
                            <Modal.Header>
                                <Modal.Title>Transaction Confirmed!</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p>✅ Transaction Submitted</p>
                                <p className="mb-0">&emsp;Contract Address:</p> <p>&emsp;{this.state.contractAddr}</p>
                                <p>✅ Transaction Confirmed</p>
                                <p>&emsp;Gas Used: {this.state.gasUsed} ETH</p>
                                <p>⏳ Contract Verification Pending...</p>
                            </Modal.Body>
                        </Modal>);
            case 4:
                return (<Modal show={true} backdrop="static" onHide={() => {this.finishDeploy()}} keyboard={false}>
                            <Modal.Header closeButton>
                                <Modal.Title>Contract Verification Submitted</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p>✅ Transaction Submitted</p>
                                <p className="mb-0">&emsp;Contract Address:</p> <p>&emsp;{this.state.contractAddr}</p>
                                <p>✅ Transaction Confirmed</p>
                                <p>&emsp;Gas Used: {this.state.gasUsed} ETH</p>
                                <p>✅ Contract Verification Submitted</p>
                                <p>&emsp;Check Etherscan for Verification Status</p>
                            </Modal.Body>
                        </Modal>);
            case 5:
                return (<Modal show={true} backdrop="static" onHide={() => {this.finishDeploy()}} keyboard={false}>
                            <Modal.Header closeButton>
                                <Modal.Title>Contract Verification Submitted</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p>✅ Transaction Submitted</p>
                                <p className="mb-0">&emsp;Contract Address:</p> <p>&emsp;{this.state.contractAddr}</p>
                                <p>❌ Transaction Failed... Please Try Again</p>
                            </Modal.Body>
                        </Modal>);
            case 6:
                return (<Modal show={true} backdrop="static" onHide={() => {this.finishDeploy()}} keyboard={false}>
                            <Modal.Header closeButton>
                                <Modal.Title>Contract Verification Submitted</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p>✅ Transaction Submitted</p>
                                <p className="mb-0">&emsp;Contract Address:</p> <p>&emsp;{this.state.contractAddr}</p>
                                <p>✅ Transaction Confirmed</p>
                                <p>&emsp;Gas Used: {this.state.gasUsed} ETH</p>
                                <p>❌ Contract Verification Failed... Please Contact Transient Labs</p>
                            </Modal.Body>
                        </Modal>);
            default:
                return;
        }
    }

    render() {
        return (
            <React.StrictMode variant="light">
                <Header loggedIn={this.state.loggedIn} user={this.state.user} onLogout={() => {this.logout()}} />
                <div className="justify-content-center d-flex align-items-center" style={{ height: "75vh" }}>
                    {this.renderBody()}
                    {this.renderModal()}
                </div>
                <p className="position-absolute bottom-0 start-0 mx-2 mb-1">© Transient Labs, 2022</p>
            </React.StrictMode>
        );
    }
}

const container = document.getElementById('app');
const root = createRoot(container);
root.render(<App />);