import React from 'react';
import { Container, ListGroup, Button, Pagination} from 'react-bootstrap';

function Contracts(props) {
    /*
    Props:
        - list of the following object [contracts]
            - contract name [name]
            - contract address [addr]
            - contract deployment status [isDeployed]
        - deploy callback function [deployCallback]
        */
    let items = [];
    for (let i = 0; i < props.contracts.length; i++) {
        let contract = props.contracts[i];
        if (contract.isDeployed) {
            let etherscan = `https://etherscan.io/address/${contract.address.toLowerCase()}`;
            items.push(<ListGroup className="mt-3" key={i}>
                            <ListGroup.Item as="div" variant="light" key={i} className="d-flex align-items-center">
                                <span className="alight-middle fs-6">✅</span> 
                                <h4 className="d-inline mb-0">&ensp;{contract.name}</h4>
                                <p className="d-inline float-end ms-auto mb-0">Deployed at <a rel="noreferrer noopener" target="_blank" href={etherscan}>{contract.address.toLowerCase()}</a></p>
                            </ListGroup.Item>
                        </ListGroup>);
        }
        else {
            items.push(<ListGroup className="mt-3" key={i}>
                            <ListGroup.Item as="div" variant="light" key={i} className="d-flex align-items-center">
                                <h4 className="d-inline mb-0 ">{contract.name}</h4>
                                <Button size="sm" className="d-inline float-end ms-auto" variant="outline-primary" onClick={() => {props.deployCallback(i)}}>Deploy</Button>
                            </ListGroup.Item>
                        </ListGroup>);
        }
    }
    let pages = props.pages;
    let pageArray = [];
    if (pages !== 1) {
        for (let i = 0; i < pages; i++) {
            if (i === props.page) {
                pageArray.push(
                    <Pagination.Item key={i} active>{i+1}</Pagination.Item>
                )
            }
            else {
                pageArray.push(
                    <Pagination.Item key={i} onClick={() => {props.paginationCallback(i)}}>{i+1}</Pagination.Item>
                )
            }
        }
    }
    
    return (
        <Container fluid>
            <h1>Smart Contracts</h1>
            {items}
            <Pagination className="mt-2" size="sm">
                {pageArray}
            </Pagination>
        </Container>
    );
}

export default Contracts