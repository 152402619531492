import React from 'react';
import { Button} from 'react-bootstrap';

class Enter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            blink: true
        };
    }

    render() {
        if (this.state.blink) {
            setTimeout(() => {this.setState({blink: !this.state.blink});}, 1000);
            return <Button id="enter" onClick={this.props.onClick} variant="outline-primary" size="lg">&gt; Enter the Lab_</Button>;
        }
        else {
            setTimeout(() => {this.setState({blink: !this.state.blink});}, 1000);
            return <Button id="enter" onClick={this.props.onClick} variant="outline-primary" size="lg">&gt; Enter the Lab<span style={{opacity: 0}}>_</span></Button>;
        }
        
    }
}

export default Enter